import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import ProductQuantity from "../components/shop/ProductQuantity"
import QuoteForm from "@webriq/gatsby-webriq-form"

export default function Quote({ data }) {
  const [loading, setLoading] = useState(true)
  const [cart, setCart] = useState(null)
  const [setItemTotal] = useState(0)

  useEffect(() => {
    // let loaded = false
    const id = setInterval(function () {
      if (window?.Ecwid) {
        setLoading(false)
        clearInterval(id)

        window.Ecwid.OnCartChanged.add(function (cart) {
          console.log("OnCartChanged", cart)
          setCart({ ...cart, items: getProductInfo(cart.items) })
        })
      }
    }, 1000)
  }, [])

  function getProductInfo(items) {
    const cartItems = items.map((item) => {
      const allProducts = data.allProducts.nodes
      const product = allProducts.find((el) => el.ecwid_id === item.product.id)

      if (product) {
        return {
          ...item,
          product: {
            ...item.product,
            originalImageUrl: product.originalImageUrl,
            defaultDisplayedPriceFormatted:
              product.defaultDisplayedPriceFormatted,
          },
        }
      }

      return item
    })

    return cartItems
  }

  function handleQuantity(index, item) {
    const productConfig = {
      id: item.product.id,
      quantity: item.quantity,
      options: item.options,
      callback: function (success, product, cart) {
        if (success) {
          setCart({ ...cart, items: getProductInfo(cart.items) })
          window.Ecwid.Cart.calculateTotal(function (order) {
            setItemTotal(order.total)
            setLoading(false)
          })
        }
      },
    }

    try {
      setLoading(true)

      setTimeout(() => {
        window.Ecwid.OnAPILoaded.add(async function () {
          await window.Ecwid.Cart.removeProduct(index)
          if (item.quantity === 0) {
            setCart({
              ...cart,
              items: cart.items.filter(
                (el) => el.product.id !== item.product.id
              ),
            })
            setLoading(false)
          } else {
            await window.Ecwid.Cart.addProduct(productConfig)
          }
        })
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout headerType="solid">
      <SeoComponent title={"quote"} description={"quote"} />
      <div className="solid-header-page-spacer" />
      <div className="container mb-5">
       {/*} {loading ? (
          <p className="text-center">Please wait a moment..</p>
        ) : cart?.items.length > 0 ? (
          <div className="row">
            <div className="col-md-6">
              {cart?.items.map((item, index) => (
                <div className="d-flex border-bottom py-3" key={index}>
                  <img
                    width={150}
                    className="img-fluid mr-2"
                    src={item.product.originalImageUrl}
                    alt={item.product.originalImageUrl}
                  />
                  <div>
                    <p>
                      <span>{item.product.name}</span>
                    </p>
                    {Object.entries(item.options).map(
                      ([_key, value], opt_index) => (
                        <p
                          key={opt_index}
                          className="d-flex flex-column mb-0 text-muted"
                        >
                          <small>{`${_key}: ${value} `}</small>
                        </p>
                      )
                    )}
                    <div className="my-2">
                      <p className="mb-0">Qty:</p>
                      <ProductQuantity
                        quantity={item.quantity}
                        handleQuantity={(quantity) =>
                          handleQuantity(index, { ...item, quantity })
                        }
                      />
                    </div>
                    <p className="mb-0">{`Price: ${item.product.defaultDisplayedPriceFormatted}`}</p>
                    <p className="mb-0">{`Total: $${(
                      item.quantity * item.product.price
                    ).toFixed(2)}`}</p>
                  </div>
                </div>
              ))}
            </div> */}
            <div className="row">
            <div className="col-md-4">
            <h3>Request a Quote</h3>
            <h6>Need a Different Configuration?</h6>
            <p>Get in touch with us if you have custom projects that you cant buy online.</p>
            </div>
            <div className="col-md-8 mx-auto">
              <div className="bg-light p-3">
                
                <QuoteForm
        method="POST"
        data-form-id="569de2ad-5717-4560-a8af-0e2e4be4742b"
        name="Quote Form"
        webriqform="true"
        data-thankyou-url="/thank-you"
      >
        {/*{items.map((el, index) => {
          const opt = Object.entries(el.options).map(
            ([_key, value]) =>
              `${_key}: ${value.length ? value.toString() : value}`
          )
          const val = `name: ${el.name}, quantity: ${el.quantity}, options: ${opt}`
          return (
            <textarea
              key={index}
              name={`product${index + 1}`}
              className="d-none"
              defaultValue={val}
            />
          )
        })}*/}
        <div className="form-group">
         <label htmlFor="fullname">Select Residential or Commercial</label>
         <select className="form-control " name="Residential/Commercial">
   
    <option value="Residential" >Residential</option>
    <option value="Commercial" >Commercial</option>
   
  </select>
        </div>
        <div className="form-group">
          <label htmlFor="fullname">Fullname</label>
          <input
            id="fullname"
            type="text"
            name="Full Name"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            name="Email Address"
            className="form-control"
            required
          />
        </div>
        {/*<div className="form-group">
          <label htmlFor="zipCode">Zip Code</label>
          <input
            id="zipCode"
            type="number"
            name="zipCode"
            className="form-control"
            required
          />
        </div>*/}
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            id="phone"
            type="number"
            name="Phone Number"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">
            What design/product are you interested in?
          </label>
          <textarea
            name="What design/product are you interested in?"
            className="form-control"
            id="message"
            rows="4"
          ></textarea>
        </div>
        <div className="mt-3 webriq-recaptcha" />
        <div className="mt-4">
          <button
            id="submit"
            type="submit"
            className="btn btn-primary text-uppercase"
          >
            Send Quote
          </button>
        </div>
      </QuoteForm>
              </div>
            </div>
          </div>
       {/*} ) : (
          <div className="text-center mb-5">
            <h3>Empty Quote</h3>
            <p>Looks like you haven't made your choice yet.</p>
          </div>
        )} */}
      </div>
    </Layout>
  )
}

export const productCart = graphql`
  query ProductCartQuery {
    allProducts: allEcwidProducts(filter: { enabled: { eq: true } }) {
      nodes {
        ecwid_id
        id
        originalImageUrl
        name
        defaultDisplayedPriceFormatted
        categoryIds
      }
    }
  }
`
